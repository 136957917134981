import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';

const Footer = props => {
  const { styles, data } = props;

  return (
    <React.Fragment>

      <Img
        style={{ maxWidth: '600px', margin: 'auto' }}
        fluid={data.subtleLogo.childImageSharp.fluid}
      />
      <footer className={styles.footer}>
        <a href="https://subtlemedical.com">Subtle Medical, 2019</a> |{' '}
        <a className={styles.ppLink} href="https://subtlemedical.com/privacy-policy/">Privacy policy</a>
      </footer>
    </React.Fragment>
  );
};

export default React.memo(Footer);
