import React from 'react';
import { fetch } from 'whatwg-fetch';
import { graphql } from 'gatsby';
import Layout from '../../components/CleanLayout';
import LandingPage from '../../components/DirectMailerTrial/LandingPage';
import styles from '../../components/DirectMailerTrial/PetTrial.module.scss';

// import Img from 'gatsby-image';
// import Palette from '../components/palette/Palette';

const METADATA = [
  {
    name: 'description',
    content:
      'For a limited time, we\'re offering free 90-day trials of SubtlePET. SubtlePET allows up to 4x faster scans on your existing scanner, improving both patient experience and scanner throughput.',
  },
  {
    name: 'keywords',
    content:
      'PET, MRI, Subtle Medical, imaging, scans, dosage, radiology, AI, deep learning, clinical setting, deployment, free trial',
  },
];

const IndexPage = ({ data }) => (
  <Layout pageTitle="Subtle Medical | SubtlePET 90-day trial" metadata={METADATA}>
    <LandingPage data={data} styles={styles} />
  </Layout>
);

export const query = graphql`
  {
    subtleLogo: file(
      relativePath: { eq: "resources/subtle/subtle_medical_logo_light.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroImage2: file(relativePath: { eq: "resources/auntminnie/subtlepet_petscan_hero_desat.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    subtlePet: file(relativePath: { eq: "resources/auntminnie/subtlepet_product_image.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    subtleMr: file(relativePath: { eq: "resources/auntminnie/subtlemr_product_image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage;
