const FDA = {
  heading: 'FDA-cleared cutting edge technology',
  body:
    'SubtlePET was developed from proprietary deep learning algorithms.  The technology applies denoising to improve image quality from up to 4x faster, noisy scans.  The result is a faster scan with image quality equivalent to the ground truth image.',
};

const WORKFLOW_EFFICIENCY = {
  heading: 'Increased workflow efficiency',
  body:
    'SubtlePET enables hospitals and imaging centers to scan more patients per day on their existing PET machines, which increases PET capacity and frees up time on the scanner for other applications like CT and cardiac PET.',
};

const PATIENT_EXPERIENCE = {
  heading: 'Better patient experience',
  body:
    'SubtlePET gets patients in and out of the scanner faster. Reduced scan times are particularly beneficial for children under sedation and those who have a hard time holding still for long periods of time. Less time in the scanner reduces patient discomfort, anxiety, and even pain.',
};

const WORKFLOW_STABILITY = {
  heading: 'No change in workflow',
  body: [
    'SubtlePET fits seamlessly into your current workflow. SubtlePET sits between the scanner and PACS.',
    'Images are sent from the scanner and are processed by SubtlePET within seconds. The high-quality images are then sent back to PACS and/or other workstations.  With 1 hour of IT time, you can be up and running.',
  ],
};

const SUBTLE_PET = {
  FDA,
  WORKFLOW_EFFICIENCY,
  PATIENT_EXPERIENCE,
  WORKFLOW_STABILITY,
};

export default SUBTLE_PET;
