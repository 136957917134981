const WAITLIST = {
  heading: 'Also interested in higher quality MRI exams?',
  body:
    'Join the waitlist \u2014 SubtleMR is an AI-based software solution used to enhance MRI images by resolution enhancement and denoising.',
  bullets: [
    'Applicable for Brain, Spine, Neck, and Knee protocols',
    'Can be used with any scanner and field strength',
    'Easy implementation with no change in workflow',
  ],
};

const MR = {
  WAITLIST,
};

export default MR;
