import React from 'react';
import styles from './BallLoader.scss';

function BallLoader() {
  return (
    <div className="ball-loader--container">
      <div className="ball-loader--ball ball-loader--bounce1" />
      <div className="ball-loader--ball ball-loader--bounce2" />
      <div className="ball-loader--ball ball-loader--bounce3" />
    </div>
  );
}

export default React.memo(BallLoader);
