import PET from './subtlePet';
import QUOTE from './quotes';
import MR from './subtleMr';

const general = {
  PET,
  QUOTE,
  MR,
};

export default general;
