import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  FormField,
  FormButton,
  IfResolved,
  IfFailed,
  IfSubmitting,
  IfActive,
  // RowBreak
} from 'react-pretty-forms';
import BallLoader from '../loaders/BallLoader';
import Retry from '../registration/Retry';
import pushToDataLayer from '../componentLogic/pushDataLayer';
import FormTitle from '../registration/FormTitle';
import persistentSubmit from '../componentLogic/persistentSubmit';

const CAMPAIGN_ID = 'DirectMailer2019 Live Demo'; // for event tracking

const orderId = `${Math.round(Date.now()).toString()}-${Math.round(Math.random() * 1000)}`;
const SUCCESS_EVENT = pushToDataLayer({ event: 'Form Submitted', campaign: CAMPAIGN_ID, orderId });
const FAIL_EVENT = pushToDataLayer({ event: 'Form Submit Failed', campaign: CAMPAIGN_ID, orderId });
const LOAD_EVENT = pushToDataLayer({ event: 'Page Loaded', campaign: CAMPAIGN_ID, orderId });

const ENDPOINT = (CONFIG = {}) => {
  switch (CONFIG.mode) {
    case 'prod':
      return `${CONFIG.endpoint}/${CONFIG.formID}/${CONFIG.sendEmail ? 'notify' : 'silent'}`;
    case 'dev':
      return `http://localhost:5000/subtleinsights/us-central1/subtleForms/${CONFIG.formID}/${CONFIG.sendEmail ? 'notify' : 'silent'}`;
    case 'wakeup':
      return `${CONFIG.endpoint}/${CONFIG.formID}/wakeup/`;
    default:
      return 'http://localhost:5000/subtleinsights/us-central1/subtleForms/misc/silent';
  }
};

/**
 * Modal
 * Expects a config option with this shape:
 * { endpoint: string, formID: string, sendEmail: enum(notify, silent) }
 * @param {*} props
 */
const WebinarRegistration = props => {
  const { config, styles, formRef, active = false } = props;
  const currForm = useRef();

  // mirror input to main form
  const setLowerFormValue = () => {
    try {
      Object.entries(currForm.current.getInputValues()).forEach(([k, v]) => {
        if (v === '') return;
        formRef.current.exposeFieldRef(k).imperativelySetValue(v);
      });
    } catch (err) { }
  };

  return (
    <React.Fragment>

      <div className={styles.webinarModalBackground} data-active={active} />

      <div className={styles.webinarModal} data-active={active}>

        <Form
          action={ENDPOINT(config)}
          wakeup
          submit={persistentSubmit}
          cssModule={styles}
          ref={currForm}
          successEvent={SUCCESS_EVENT}
          failEvent={FAIL_EVENT}
          loadEvent={LOAD_EVENT}
          className={styles.webinarFormBox}
        >
          <IfActive>
            <FormField type="text" name="first" label="First name*" required />
            <FormField type="text" name="last" label="Last name*" required />
            <FormField type="email" name="email" label="Email*" required />
            <FormButton type="submit" value="Register" className={styles.primaryButton} onClick={setLowerFormValue} />
          </IfActive>
          <IfResolved>
            <div className={styles.thankyouComponent}>
              Thank you for registering.
              <br />
              <br />
              <span style={{ display: 'inline-block' }}>
                We'll send you a confirmation email soon.
              </span>
            </div>
          </IfResolved>
          <IfSubmitting>
            <BallLoader />
          </IfSubmitting>
          <IfFailed>
            <Retry form={formRef} />
          </IfFailed>
        </Form>
      </div>

    </React.Fragment>

  );
};

WebinarRegistration.propTypes = {
  config: PropTypes.shape({
    formID: PropTypes.string,
    sendEmail: PropTypes.oneOf(['notify', 'silent']),
    endpoint: PropTypes.string,
  }).isRequired,
  styles: PropTypes.objectOf(PropTypes.string),
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(React.Component),
    }),
  ]).isRequired,
};

WebinarRegistration.defaultProps = {
  styles: {},
};


export default WebinarRegistration;
