import React from 'react';

const SectionHeading = React.memo(props => {
  const { children, className = '' } = props;
  return (
    <h3 className={className}>
      {children}
    </h3>
  );
});

export default SectionHeading;
