const React = require('react');

export default function ControlPanel(props) {
    const { form } = props;
    console.log('control panel:', form);

    return (
        <React.Fragment>
            <div onClick={() => { form.setState({ formState: 'active' }); }}>Active</div>
            <div onClick={() => { form.saveFormInput(); form.setState({ formState: 'submitting' }); }}>Submitting</div>
            <div onClick={() => { form.saveFormInput(); form.setState({ formState: 'failed' }); }}>Failed</div>
            <div onClick={() => { form.saveFormInput(); form.setState({ formState: 'resolved' }); }}>Resolved</div>
            <div onClick={() => { console.log(form.getInputValues()); }}>Log current form values</div>
        </React.Fragment>
    );
}
