import { fetch } from 'whatwg-fetch';

const DEFAULT_TIMEOUT = 4000;
const DEFAULT_ATTEMPTS = 3;

// export default
export default function persistentSubmit(
  action,
  formValues,
  { retryInterval = DEFAULT_TIMEOUT, retryLimit = DEFAULT_ATTEMPTS, count = 1 } = {},
) {
  function submit() {
    return fetch(action, {
      method: 'POST',
      body: JSON.stringify(formValues),
      credentials: 'include',
      redirect: 'manual',
      // requires setting access-control-allow-headers
      // headers: {
      //   'Custom-Cookie': window.document.cookie,
      // },
    });
  }

  // console.log('attempt', count);
  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(() => reject(Error('Request timed out')), retryInterval);
  });

  const submitPromise = submit();

  return Promise.race([timeoutPromise, submitPromise])
    .then(body => {
      console.log('success');
      try {
        return body.text();
      } catch (err) {
        return body;
      }
    })
    .then(res => {
      try {
        const parse = JSON.parse(res);
        if (parse.redirect) {
          // window.location.replace(parse.redirect);
          window.location.href = parse.redirect; // allows back button
          return 'OVERRIDE';
        }
      } catch (err) {
        return res;
      }
    })
    .catch(err => {
      if (count < retryLimit) {
        console.error(err);
        return persistentSubmit(action, formValues, {
          retryInterval: retryInterval * 2,
          retryLimit,
          count: count + 1,
        });
      }
      console.log('Exceeded retry attempts');
      throw Error('Exceeded retry attenpts');
    });
}
