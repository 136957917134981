import React, { useEffect, useRef, useState } from 'react';
import {
  Form,
  FormField,
  // FormSelect,
  // FormCheckbox,
  FormButton,
  IfResolved,
  IfFailed,
  IfSubmitting,
  IfActive,
  // RowBreak
} from 'react-pretty-forms';
import BallLoader from '../loaders/BallLoader';
import Retry from '../registration/Retry';
import pushToDataLayer from '../componentLogic/pushDataLayer';
import FormTitle from '../registration/FormTitle';
import persistentSubmit from '../componentLogic/persistentSubmit';

const CAMPAIGN_ID = 'DirectMailer2019'; // for event tracking

const orderId = `${Math.round(Date.now()).toString()}-${Math.round(
  Math.random() * 1000
)}`;
const SUCCESS_EVENT = pushToDataLayer({
  event: 'Form Submitted',
  campaign: CAMPAIGN_ID,
  orderId,
});
const FAIL_EVENT = pushToDataLayer({
  event: 'Form Submit Failed',
  campaign: CAMPAIGN_ID,
  orderId,
});
const LOAD_EVENT = pushToDataLayer({
  event: 'Page Loaded',
  campaign: CAMPAIGN_ID,
  orderId,
});

const ENDPOINT = (CONFIG = {}) => {
  switch (CONFIG.mode) {
    case 'prod':
      return `${CONFIG.endpoint}/${CONFIG.formID}/${
        CONFIG.sendEmail ? 'notify' : 'silent'
      }`;
    case 'dev':
      return `http://localhost:5000/subtleinsights/us-central1/subtleForms/${
        CONFIG.formID
      }/${CONFIG.sendEmail ? 'notify' : 'silent'}`;
    case 'wakeup':
      return `${CONFIG.endpoint}/${CONFIG.formID}/wakeup/`;
    default:
      return 'http://localhost:5000/subtleinsights/us-central1/subtleForms/misc/silent';
  }
};

const RegistrationForm = ({ styles, formRef, config }) => {
  useEffect(() => {
    // wake endpoint
    fetch(ENDPOINT(config), {
      method: 'POST',
    }).then(success => {
      console.log('Endpoint awake');
    });
  }, []);

  // const formRef = useRef(null);
  const formWrapperRef = useRef(null);
  const [formHeight, setFormHeight] = useState(100);

  useEffect(() => {
    // set min height of form
    const currHeight = Math.round(
      formWrapperRef.current.getBoundingClientRect().height
    );
    if (currHeight > 100 && currHeight !== formHeight) {
      setFormHeight(currHeight);
    }
  });

  return (
    <React.Fragment>
      <div className={`${styles.formHeadingWrapper} ${styles.bodyText}`}>
        <h3 className={styles.formHeading}>4.5 minute PET scans?</h3>
        <p style={{ textAlign: 'center' }}>
          Register by May 1st, 2020 to receive your free 90-day trial*
        </p>
      </div>

      <section className={styles.flexForm}>
        <div
          style={{ minHeight: `${formHeight}px` }}
          className={styles.formBox}
          ref={formWrapperRef}
        >
          <Form
            action={ENDPOINT(config)}
            submit={persistentSubmit}
            cssModule={styles}
            ref={formRef}
            successEvent={SUCCESS_EVENT}
            failEvent={FAIL_EVENT}
            loadEvent={LOAD_EVENT}
          >
            <FormTitle styles={styles}>SubtlePET free trial</FormTitle>
            <IfActive>
              {/* <FormSelect name="salutation" label="Preferred salutation" defaultValue="unselected">
                <option value="">-</option>
                <option value="Dr.">Dr.</option>
                <option value="Prof.">Prof.</option>
              </FormSelect> */}
              <FormField
                type="text"
                name="first"
                label="First name"
                required
              />
              <FormField type="text" name="last" label="Last name" required />
              {/* <FormField type="text" name="role" label="Job Title" /> */}
              <FormField
                type="text"
                name="institution"
                label="Institution"
                required
              />
              <FormField type="email" name="email" label="Email" required />
              {/* <FormField type="text" name="promo" label="Promo code" /> */}
              <FormField
                type="hidden"
                name="source"
                initialValue="direct"
              />
              {/* <RowBreak>
                <div style={{ textAlign: 'left' }}>I'm interested in:</div>
              </RowBreak>
              <FormCheckbox
                name="PET"
                label="PET"
                value="PET"
              /> */}
              {/* <FormCheckbox
                name="MR"
                label="MR"
                value="MR"
              /> */}
              <FormButton
                type="submit"
                value="Register"
                className={styles.primaryButton}
              />
              <div
                style={{ textAlign: 'left', padding: '2rem 2rem 0 2rem', fontSize: '90%' }}
                // _prettyType="note"
              >
                *Only available in the US
              </div>
            </IfActive>
            <IfResolved>
              <div className={styles.thankyouComponent}>
                Thank you for signing up!
                <br />
                <br />
                <span style={{ display: 'inline-block' }}>
                  We'll contact you with further details soon.
                </span>
              </div>
            </IfResolved>
            <IfSubmitting>
              <BallLoader />
            </IfSubmitting>
            <IfFailed>
              <Retry form={formRef} />
            </IfFailed>
          </Form>
        </div>
      </section>
    </React.Fragment>
  );
};

export default RegistrationForm;
