const BRANT = {
  body:
    "Focusing Subtle Medical's SubtlePET AI platform on faster image acquisition, we have been able to dramatically increase PET scan efficiency and provide a superior patient experience. SubtlePET technology allows us to scan a patient four times faster than normal, yet maintain equal image quality, not otherwise impacting work flow. This creates immediate ROI benefit for our hospital and a compelling value proposition.",
  author: 'Michael Brant-Zawadzki, MD, FACR',
  citation: '',
};

const HESS = {
  body:
    'One of the most exciting things about deep learning reconstruction is how it redefines the usual negotiation between exam time and image quality. This could lead to significant downstream value for imaging operations and for patient experience.',
  author: 'Christopher Hess, MD',
  citation: 'Chair of the Department of Radiology and Biomedical Imaging at UCSF',
};

const quotations = {
  BRANT,
  HESS,
};

export default quotations;
