import React, { useEffect, useRef, useState } from 'react';
import Img from 'gatsby-image/withIEPolyfill';
// import PropTypes from 'prop-types';
import Hero from './Hero';
import Body from './Body';
import Footer from './Footer';

import RegistrationForm from './RegistrationForm';

import { FormStatusWrapper } from 'react-pretty-forms';
import ControlPanel from '../registration/ControlPanel';

import { LATEST } from '../registration/version';
const CONFIG = {
  mode: 'prod',
  sendEmail: true,
  formID: 'pet-trial',
  endpoint: LATEST,
};

export default function RSNA({ styles, data }) {
  const formRef = useRef();

  return (
    <React.Fragment>

      <Hero styles={styles} data={data} formRef={formRef} />

      <Body styles={styles} data={data} />

      <hr className={styles.sectionDivider} />

      <div id="register" className={styles.formWrapper}>

        <RegistrationForm styles={styles} formRef={formRef} config={CONFIG} />

        <hr className={styles.sectionDivider} />

        <Footer styles={styles} data={data} />
      </div>

    </React.Fragment>

  );
}
