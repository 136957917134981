const formApiA = 'https://us-central1-subtleinsights.cloudfunctions.net/submitForm';
const formApiB = 'https://us-central1-subtleinsights.cloudfunctions.net/submitRegistration';
const subtleFormsV2 = 'https://us-central1-subtleinsights.cloudfunctions.net/subtleFormsListener';
const subtleFormsV3 = 'https://us-central1-subtleinsights.cloudfunctions.net/subtleFormsV3';
const subtleFormsV4 = 'https://us-central1-subtleinsights.cloudfunctions.net/subtleFormsV4';
const subtleFormsV5 = 'https://us-central1-subtleinsights.cloudfunctions.net/subtleFormsV5';
const subtleFormsV6 = 'https://us-central1-subtleinsights.cloudfunctions.net/subtleFormsV6';

const STABLE = subtleFormsV3;
export const LATEST = subtleFormsV6;
export const WITH_WAITLIST = subtleFormsV4;
export default STABLE;
