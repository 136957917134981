import React from 'react';
import PropTypes from 'prop-types';
import SectionHeading from './SectionHeading';

const SectionBlock = (props) => {
  const {
    styles,
    children: { heading, body, bullets },
    headingClass,
    bodyClass,
  } = props;

  let subPoints = null;
  if (Array.isArray(bullets)) {
    subPoints = (
      <ul>
        {bullets.map((el) => (
          <li key={el.slice(0, 7)}>
            {el}
          </li>
        ))}
      </ul>
    );
  }

  let bodyComponent = body;
  if (Array.isArray(body)) {
    bodyComponent = body.map(el => (
      <p className={styles[bodyClass]} key={el.slice(0, 7)}>
        {el}
      </p>
    ));
  } else if (typeof body === 'string') {
    bodyComponent = <p className={styles[bodyClass]}>{body}</p>;
  }

  return (
    <React.Fragment>
      <SectionHeading className={styles[headingClass]}>
        {heading}
      </SectionHeading>
      {bodyComponent}
      {subPoints}
    </React.Fragment>
  );
};

SectionBlock.propTypes = {
  children: PropTypes.shape({
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.element]),
  }).isRequired,
  styles: PropTypes.objectOf(PropTypes.string).isRequired,
  bodyClass: PropTypes.string,
  headingClass: PropTypes.string,
};

SectionBlock.defaultProps = {
  bodyClass: 'sectionBody',
  headingClass: 'sectionHeader',
};

export default React.memo(SectionBlock);
