import React, { useState } from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import WebinarRegistration from './WebinarRegistrationForm';
import { LATEST } from '../registration/version';

const CONFIG = {
  formID: 'pet-trial-live-demo',
  sendEmail: 'silent',
  endpoint: LATEST,
};

const Hero = ({ styles, data, formRef }) => {
  const [formActive, setFormState] = useState(false);
  

  return (
    <div className={styles.heroImageWrapper}>
      <Img
        className={styles.heroImage}
        fluid={data.heroImage2.childImageSharp.fluid}
        objectPosition="15% 25%"
      />
      <section className={styles.heroWrapper}>
        <div className={styles.heroTextWrapper}>
          <h1 className={styles.heroTitle}>
            Ready for happier patients and higher throughput?
          </h1>
          <div className={styles.shortDivider} />
          <h2 className={styles.heroSubtitle}>
            Start your 90-day free trial now
          </h2>
          <div className={styles.divider} />
          <div className={styles.ctaWrapper}>

            <a href="#register"><button type="button" className={styles.primaryButton}>Activate trial</button></a>

          </div>
          <div className={styles.divider} />
          {/* <h3 className={styles.heroSubtitle2}>
            or <a
              onClick={e => {
                e.preventDefault();
                setFormState(!formActive);
              }}
              className={styles.secondaryButton}
              target="_blank"
              rel="noopener noreferrer"
              href="https://register.gotowebinar.com/register/5347482900848956173"
            >
              attend a live demo
            </a> on November 12th, 10am PST
          </h3> */}

          {/* <WebinarRegistration
            active={formActive}
            styles={styles}
            formRef={formRef}
            config={CONFIG}
          /> */}
        </div>

        {/* <div className={styles.heroBuffer} /> */}

        <div className={styles.logoImageWrapper}>
          {/* <div className={styles.heroImageDivider} /> */}

        </div>
      </section>

    </div>
  );
};

export default React.memo(Hero);
