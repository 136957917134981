import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image/withIEPolyfill';

import SectionBlock from '../landingPage/SectionBlock';
// import SectionHeading from '../landingPage/SectionHeading';
import Quotation from '../landingPage/Quotation';
import COPY from '../../marketing/index';

const Body = (props) => {
  const { styles = {}, data = {} } = props;

  return (
    <main className={styles.contentArea}>

      <div className={styles.sectionDividerHero} />

      <div className={styles.pageBody}>

        <section id="learn-more" className={styles.bodyText}>

          <SectionBlock styles={styles}>
            {COPY.PET.FDA}
          </SectionBlock>

          <Img
            fluid={data.subtlePet.childImageSharp.fluid}
            className={styles.bodyImage}
          />

          <SectionBlock styles={styles}>
            {COPY.PET.WORKFLOW_EFFICIENCY}
          </SectionBlock>

          <SectionBlock styles={styles}>
            {COPY.PET.PATIENT_EXPERIENCE}
          </SectionBlock>

          <Quotation styles={styles}>
            {COPY.QUOTE.BRANT}
          </Quotation>

          <SectionBlock styles={styles}>
            {COPY.PET.WORKFLOW_STABILITY}
          </SectionBlock>

          <div style={{ textAlign: 'center' }}>
            <a href="#register">
              <button type="button" className={styles.primaryButton}>
                Sign me up!
              </button>
            </a>
          </div>

          <SectionBlock styles={styles}>
            {COPY.MR.WAITLIST}
          </SectionBlock>

          <Img
            fluid={data.subtleMr.childImageSharp.fluid}
            className={styles.bodyImageMr}
          />

          <Quotation styles={styles}>
            {COPY.QUOTE.HESS}
          </Quotation>

          <p className={styles.inquiries}>
            For SubtleMR inquiries, please email sales@subtlemedical.com
          </p>

        </section>
      </div>
    </main>
  );
};

Body.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string).isRequired,
  data: PropTypes.shape({
    subtleMr: PropTypes.object,
    subtlePet: PropTypes.object,
  }).isRequired,
};

export default React.memo(Body);
