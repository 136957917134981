/* global window */

/**
 * Creates a function that pushes an event to GA
 * datalayer. (Pass to Pretty Form as successEvent or failEvent)
 */
export default function pushToDataLayer(event) {
  return (instance, ...otherFields) => {
    if (typeof window === 'undefined' || !window.dataLayer) return;
    if (otherFields) Object.assign(event, otherFields);
    window.dataLayer.push(event);
  };
}
