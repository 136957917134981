import React from 'react';
import PropTypes from 'prop-types';

const Quotation = (props) => {
  const {
    className,
    authorClass = '',
    citationClass = '',
    styles,
    children: {
      body,
      author,
      citation,
    },
  } = props;

  return (
    <blockquote className={styles[className]}>
      "{body}"
      <footer className={styles[authorClass]}>
        {'\u2014'} {author}{citation ? ',' : ''} <cite className={styles[citationClass]}>{citation}</cite>
      </footer>
    </blockquote>
  );
};

Quotation.propTypes = {
  children: PropTypes.shape({
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    author: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    citation: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }).isRequired,
  className: PropTypes.string,
  authorClass: PropTypes.string,
  citationClass: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

Quotation.defaultProps = {
  className: 'quotationBlock',
  authorClass: 'quotationAuthor',
  citationClass: 'quotationCitation',
  styles: {},
};

export default React.memo(Quotation);
