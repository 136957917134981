import React, { useMemo } from 'react';

function RetryForm(props) {
  const { form, formState } = props;
  // console.log('retry form')
  // console.log(form.current.setFormState);
  const retryForm = useMemo(() => {
    return () => form.current.setFormState('active');
  }, [form]);

  return (
    <React.Fragment>
      <div>There was an error submitting the form.<br />Please try again.</div>
      <br />
      <button
        type="button"
        className="form--button-retry form--button"
        onClick={retryForm}
        data-formstate={formState}
      >
        Try again
      </button>
    </React.Fragment>
  );
}

const RetryFormMemo = React.memo(RetryForm);
export default RetryFormMemo;
